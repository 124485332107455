<template>
  <el-row :gutter="20">
    <el-col :span="8">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <i class="el-icon-message-solid"></i> <span>告警事件列表</span>
          <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i></el-button>
        </div>
        <WarnEventList/>
      </el-card>

    </el-col>
    <el-col :span="16">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>告警事件详情</span>
          <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i></el-button>
        </div>
        <WarnEventDetails/>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import WarnEventList from "./WarnEventList.vue"
import WarnEventDetails from "./WarnEventDetails.vue"

export default {
  name: "EventCenter",
  components: {
    WarnEventList,
    WarnEventDetails,
  },
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

</style>