<template>
  <div class="details">
    <div class="warn-details-information" v-if="detailInformations===''">
      <div class="line-details">
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item label-width="0">
            暂无数据信息
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else>
      <div class="warn-descriptions">
        <el-row :gutter="20">
          <el-col :span="1">
            <el-divider direction="vertical"></el-divider>
          </el-col>
          <el-col :span="22">
            <div class="line-details"><i class="el-icon-bell"></i> <span>类型：{{ detailInformations.warnningType }}</span>
            </div>
            <div class="line-details"><i class="el-icon-time"></i> <span>{{ detailInformations.datetime }}</span></div>
          </el-col>
        </el-row>
      </div>
      <div class="warn-details">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleDetails">
          <el-menu-item index="1"><i class="el-icon-warning-outline"></i>报警详情</el-menu-item>
          <el-menu-item index="2"><i class="el-icon-chat-line-round"></i>短信记录</el-menu-item>
        </el-menu>
        <div class="line"></div>
        <div class="warn-details-information" v-show="showDetails.menuIndex1">
          <el-row :gutter="20">
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="22">
              <div class="line-details">
                <el-descriptions>
                  <el-descriptions-item label="报警标题"><span>{{ detailInformations.warnningType }}</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="报警等级"><span>{{ detailInformations.level }} 级</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="报警时间"><span>{{ detailInformations.datetime }}</span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div class="line-details">
                <el-row>
                  <el-col :span="8">
                    <el-descriptions>
                      <el-descriptions-item label="报警内容"><span><div
                          style="width: 200px">{{ detailInformations.address }}，在{{
                          detailInformations.datetime
                        }},发出{{ detailInformations.warnningType }}报警，请及时进行处理 。</div></span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-col>
                  <el-col :span="8">
                    <el-descriptions>
                      <el-descriptions-item label="状态">
                      <span v-if="detailInformations.handled==0" size="mini" round>待处理
                      </span>
                        <span v-else size="mini" round type="primary">已解除
                      </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-col>
                  <el-col :span="8">
                    <el-descriptions>
                      <el-descriptions-item label="解除时间"><span>{{ detailInformations.handledTime }}</span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-col>
                </el-row>
              </div>
              <div class="line-details">
                <el-descriptions>
                  <el-descriptions-item label="解除原因"><span>{{ detailInformations.handledDesc }}</span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
              <div class="line-details"></div>
            </el-col>
          </el-row>
        </div>
        <div class="warn-details-information" v-show="showDetails.menuIndex2">
          <div class="line-details">
            <el-form ref="ruleForm" label-width="100px">
              <el-form-item label-width="0">
                短信记录 完善中..
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleMedias">
          <el-menu-item index="1"><i class="el-icon-camera"></i>报警图片</el-menu-item>
          <el-menu-item index="2"><i class="el-icon-video-camera"></i>视频详情</el-menu-item>
        </el-menu>
        <div class="warn-details-information" v-show="showMedias.menuIndex1">
          <el-card shadow="never" class="media-details">
            <div class="demo-image__preview" v-show="!detailInformations.warnningImgUrl == ''">
              <el-image
                  class="media"
                  :src="detailInformations.warnningImgUrl"
                  :preview-src-list="[detailInformations.warnningImgUrl]">
              </el-image>
            </div>
            <div v-show="detailInformations.warnningImgUrl == ''">
              <el-row>
                <div style="font-size: 16px;margin-top: 35px">
                  <b>上次加盐</b>：{{ detailInformations.prevdatetime }}
                </div>
              </el-row>
              <el-row>
                <div style="font-size: 16px;margin-top: 35px">
                  {{ detailInformations.address }}，在{{
                    detailInformations.datetime
                  }},发出{{ detailInformations.warnningType }}报警，请及时进行处理 。
                </div>
              </el-row>
            </div>
          </el-card>
        </div>
        <div class="warn-details-information" v-show="showMedias.menuIndex2">
          <el-card shadow="never" class="media-details">
            <video src="videos/test_mv02.mov" muted autoplay class="media"></video>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "../store/bus.js";

export default {
  name: "WarnEventDetails",
  data() {
    return {
      activeIndex: '1',
      detailInformations: '',
      showDetails: {
        'menuIndex1': true,
        'menuIndex2': false,
      },
      showMedias: {
        'menuIndex1': true,
        'menuIndex2': false,
      },
    }
  },
  methods: {
    handleDetails(key, keyPath) {
      let selectMenuIndex = 'menuIndex' + keyPath[0]
      this.showDetails[selectMenuIndex] = true
      let showDetails = this.showDetails
      Object.keys(this.showDetails).forEach(function (item) {
        if (item !== selectMenuIndex) {
          showDetails[item] = false
        }
      })
      this.showDetails = showDetails
    },
    handleMedias(key, keyPath) {
      let selectMenuIndex = 'menuIndex' + keyPath[0]
      this.showMedias[selectMenuIndex] = true
      let showMedias = this.showMedias
      Object.keys(this.showMedias).forEach(function (item) {
        if (item !== selectMenuIndex) {
          showMedias[item] = false
        }
      })
      this.showMedias = showMedias
    }
  },
  mounted() {
    EventBus.$on("Informations", (msg) => {
      // WarnEventDetails组件接受 WarnEventList发送来的消息
      let date = new Date(msg.fields.datetime);
      msg.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
      let prevdate = date.setHours(date.getHours() - 1);
      msg.fields.prevdatetime = this.$moment(prevdate).format('YYYY-MM-DD HH:mm:ss')
      this.detailInformations = msg.fields
    });
  },
}
</script>

<style scoped>
.warn-descriptions .el-row {
  margin-top: 10px;
}

.warn-details {
  margin-top: 15px;
}

.warn-details-information {
  margin-top: 15px;
}

.warn-details-information span {
  font-weight: bold;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}

.line-details {
  margin-top: 15px;
}

.el-divider--vertical {
  height: 75px;
  width: 5px;
  background-color: #409EFF;
}

.el-form-item {
  text-align: center;
}

.media-details {
  background-color: transparent;
  border: 0;
}

.details {
  height: 79vh;
}

.media {
  height: 30vh;
}
</style>