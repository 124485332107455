<template>
  <div class="list">
    <div class="warn-input" style="margin-bottom: 10px">
      <el-row :gutter="2">
        <div>
          <el-col :span="4">
            <el-select v-model="factorychildtype_select" placeholder="厂区"
                       @change="selectAlarm('factory')">
              <el-option
                  v-for="item in factorychildtype_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select v-model="factorytype_select" placeholder="摄像头"
                       @change="selectAlarm('address')">
              <el-option
                  v-for="item in factorytype_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="warnningtype_select" placeholder="类型"
                       @change="selectAlarm('warnningType')">
              <el-option
                  v-for="item in warnningtype_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="leveltype_select" placeholder="等级"
                       @change="selectAlarm('level')">
              <el-option
                  v-for="item in leveltype_options"
                  :key="item.value"
                  :label="item.label+'级'"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="statustype_select" placeholder="状态" @change="selectAlarm('status')">
              <el-option
                  v-for="item in statustype_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <button @click="resetSelect"
                    style="width: 100%;height: 40px;
                          border-radius:4px;background-color:#FFF;
                          color:#606266;border: 1px solid #DCDFE6;">
              重置
            </button>
          </el-col>
        </div>
      </el-row>
    </div>
    <div class="warn-input">
      <el-row :gutter="23">
        <el-col :span="17">
          <el-date-picker
              v-model="value_dateTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="timestamp"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="搜索" v-model="value">
            <el-button type="primary" slot="append" icon="el-icon-search" @click="searchAlarm"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div class="warn-list">
      <div class="warn-short" v-for="item in warn_data_list" :key="item.pk" @click="sendInformations(item)">
        <el-card shadow="hover" :class="item.show ? 'active':''">
          <el-row :gutter="20">
            <el-col :span="14">
              <i class="el-icon-message-solid"></i> <span>{{ item.fields.warnningType }}</span>
            </el-col>
            <el-col :span="10">
              <el-button style="float: right;margin-right: 10px" type="text">{{ item.fields.datetime }}</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="14">
              <div class="grid-content bg-purple">{{ item.fields.address }}，发出{{ item.fields.warnningType }}报警。</div>
            </el-col>
            <el-col :span="10">
              <el-row style="float: right">
                <div v-show="operatorRes">
                  <el-button size="mini" round @click="deleteAlarm(item)"><i class="el-icon-close"></i> 删除</el-button>
                  <el-button v-if="item.fields.handled==0" size="mini" round
                             @click="handledAlarm(item)"><i class="el-icon-message-solid"></i> 解除
                  </el-button>
                  <el-button v-else size="mini" round type="primary"><i class="el-icon-message-solid"></i> 已解除
                  </el-button>
                </div>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
    <div class="warn-pagination">
      <el-pagination
          small
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="paginationNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {handled, queryAlarmAll, delAlarm, queryProjects} from '../../api/requests.js'
import {EventBus} from "../store/bus.js";

export default {
  name: "WarnEventList",
  data() {
    return {
      value: '',
      value_dateTime: null,
      cardStyle: true,
      operatorRes: true,
      currentPage: 1,
      paginationNum: 10,

      factorychildtype_select: '',
      warnningtype_select: '',
      factorytype_select: '',
      leveltype_select: '',
      statustype_select: '',
      factorychild_options: {},
      statustypeDefault: {'待处理': '0', '已处理': '1'},

      factorychildtype_options: [],
      warnningtype_options: [],
      factorytype_options: [],
      leveltype_options: [],
      statustype_options: [],

      factorychildtype_optionsDefault: [],
      warnningtype_optionsDefault: [],
      factorytype_optionsDefault: [],
      leveltype_optionsDefault: [],
      statustype_optionsDefault: [],

      warn_data: [],
      warn_data_cache: [],
      warn_data_list: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.warn_data_list = this.warn_data.slice((val - 1) * 10, val * 10)
      this.sendInformations(this.warn_data_list[0])
    },
    searchAlarm() {
      let warnData = this.warn_data
      let searchData = []
      warnData.forEach(res => {
        if (JSON.stringify(res.fields).match(this.value) != null) {
          searchData.push(res)
        }
      })

      this.warn_data_list = searchData.slice(0, 10)
      this.warn_data = searchData
      this.currentPage = 1
      this.paginationNum = Object.keys(searchData).length
      this.sendInformations(this.warn_data_list[0])

    },
    getTimeNow() {
      let myDate = new Date();    //创建Date对象
      let Y = myDate.getFullYear();   //获取当前完整年份
      let M = myDate.getMonth() + 1;  //获取当前月份
      let D = myDate.getDate();   //获取当前日1-31
      let H = myDate.getHours();  //获取当前小时
      let i = myDate.getMinutes();    //获取当前分钟
      let s = myDate.getSeconds();    //获取当前秒数
      if (M < 10) {
        M = '0' + M;
      }
      // 日不足10补0
      if (D < 10) {
        D = '0' + D;
      }
      // 小时不足10补0
      if (H < 10) {
        H = '0' + H;
      }
      // 分钟不足10补0
      if (i < 10) {
        i = '0' + i;
      }
      // 秒数不足10补0
      if (s < 10) {
        s = '0' + s;
      }
      return Y + '-' + M + '-' + D + ' ' + H + ':' + i + ':' + s;
    },
    handledAlarm(item) {
      let user = localStorage.getItem('Authorization')
      this.warn_data_list.forEach(res => {
        if (res === item) {
          res.fields.handled = '1' // 已处理
          res.fields.handledTime = this.getTimeNow()
        }
      })
      let param = {'info': user, 'alarm': JSON.stringify(item)}
      handled(param).then(res => {
        console.log(res)
      })
    },
    deleteAlarm(item) {
      let user = localStorage.getItem('Authorization')
      let param = {'info': user, 'alarm': JSON.stringify(item)}
      let warn_data = this.warn_data
      warn_data = warn_data.filter(function (res) {
        return res != item
      });
      delAlarm(param).then(res => {
        if (res.data.code == 200) {
          this.warn_data = warn_data
          let l_start = (this.currentPage - 1) * 10
          let l_end = this.currentPage * 10
          this.warn_data_list = warn_data.slice(l_start, l_end)
          this.sendInformations(this.warn_data_list[0])
        }
      })
    },
    sendInformations(item) {
      // 改变选中
      this.warn_data_list.forEach(res => res['show'] = false)
      item.show = !item.show
      EventBus.$emit('Informations', item)
    },

    resetSelect() {
      this.warnningtype_select = '',
          this.factorychildtype_select = '',
          this.factorytype_select = '',
          this.leveltype_select = '',
          this.statustype_select = '',
          this.value = '',
          this.value_dateTime = null,
          this.warn_data = this.warn_data_cache
      let factorychildtype_options = this.factorychildtype_optionsDefault
      let warnningtype_options = this.warnningtype_optionsDefault
      let leveltype_options = this.leveltype_optionsDefault
      let factorytype_options = this.factorytype_optionsDefault
      let statustype_options = this.statustype_optionsDefault
      this.factorychildtype_options = factorychildtype_options
      this.warnningtype_options = warnningtype_options
      this.leveltype_options = leveltype_options
      this.factorytype_options = factorytype_options
      this.statustype_options = statustype_options

      let cache = this.warn_data_cache
      this.warn_data = cache
      this.warn_data_list = cache.slice(0, 10)
      this.paginationNum = Object.keys(cache).length
      this.sendInformations(this.warn_data_list[0])

    },
    selectFormat(data) {
      let select = []
      Array.from(new Set(data)).forEach(res => {
        let defult = {
          value: '',
          label: ''
        }
        defult['value'] = res
        defult['label'] = res
        select.push(defult)
      })
      return select
    },
    selectAlarm(selectType) {
      let alarm = this.warn_data
      let warn_data_show = []

      let factorychild_arr = []
      let warnning_arr = []
      let factory_arr = []
      let level_arr = []
      let handle_arr = []
      if (selectType == 'factory') {
        this.factorychildtype_options.forEach(res => {
          factorychild_arr.push(res.value)
        })
        this.factorytype_select = ''
        this.warnningtype_select = ''
        this.leveltype_select = ''
        this.statustype_select = ''
      }
      if (selectType == 'address') {
        this.factorytype_options.forEach(res => {
          factory_arr.push(res.value)
        })
        this.factorychildtype_options.forEach(res => {
          factorychild_arr.push(res.value)
        })
        this.warnningtype_select = ''
        this.leveltype_select = ''
        this.statustype_select = ''
      }
      if (selectType == 'warnningType') {
        this.warnningtype_options.forEach(res => {
          warnning_arr.push(res.value)
        })
        this.factorytype_options.forEach(res => {
          factory_arr.push(res.value)
        })
        this.factorychildtype_options.forEach(res => {
          factorychild_arr.push(res.value)
        })
        this.leveltype_select = ''
        this.statustype_select = ''
      }
      if (selectType == 'level') {
        this.leveltype_options.forEach(res => {
          level_arr.push(res.value)
        })
        this.warnningtype_options.forEach(res => {
          warnning_arr.push(res.value)
        })
        this.factorytype_options.forEach(res => {
          factory_arr.push(res.value)
        })
        this.factorychildtype_options.forEach(res => {
          factorychild_arr.push(res.value)
        })
        this.statustype_select = ''
      }
      if (selectType == 'status') {
        this.statustype_options.forEach(res => {
          handle_arr.push(res.value)
        })
        this.leveltype_options.forEach(res => {
          level_arr.push(res.value)
        })
        this.warnningtype_options.forEach(res => {
          warnning_arr.push(res.value)
        })
        this.factorytype_options.forEach(res => {
          factory_arr.push(res.value)
        })
        this.factorychildtype_options.forEach(res => {
          factorychild_arr.push(res.value)
        })
      }

      alarm.forEach(res => {
        if (this.factorychildtype_select && this.factorytype_select && this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) && this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.leveltype_select && this.factorychildtype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.factorychildtype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.factorychildtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.leveltype_select === res.fields.level &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level &&
              this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.leveltype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType && this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.factorychildtype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType && this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.factorychildtype_select && this.leveltype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) && this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.warnningtype_select && this.leveltype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.warnningtype_select === res.fields.warnningType && this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.factorychildtype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.warnningtype_select && this.statustype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.warnningtype_select === res.fields.warnningType && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.leveltype_select && this.factorychildtype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.leveltype_select === res.fields.level && this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.leveltype_select && this.statustype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.leveltype_select && this.statustype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.leveltype_select && this.factorychildtype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level && this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.factorychildtype_select && this.statustype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.leveltype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.factorychildtype_select) {
          if (this.warnningtype_select === res.fields.warnningType &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.leveltype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.warnningtype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.warnningtype_select === res.fields.warnningType) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.factorychildtype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.warnningtype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) &&
              this.warnningtype_select === res.fields.warnningType) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.leveltype_select) {
          if (this.factorytype_select === res.fields.address &&
              this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select && this.statustype_select) {
          if (this.factorytype_select === res.fields.address && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select && this.statustype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address) && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select && this.statustype_select) {
          if (this.warnningtype_select === res.fields.warnningType && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.leveltype_select && this.statustype_select) {
          if (this.leveltype_select === res.fields.level && this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        } else if (this.leveltype_select && this.factorychildtype_select) {
          if (this.leveltype_select === res.fields.level && this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorychildtype_select) {
          if (this.factorychild_options[this.factorychildtype_select].includes(res.fields.address)) {
            warn_data_show.push(res)
          }
        } else if (this.factorytype_select) {
          if (this.factorytype_select === res.fields.address) {
            warn_data_show.push(res)
          }
        } else if (this.warnningtype_select) {
          if (this.warnningtype_select === res.fields.warnningType) {
            warn_data_show.push(res)
          }
        } else if (this.leveltype_select) {
          if (this.leveltype_select === res.fields.level) {
            warn_data_show.push(res)
          }
        } else if (this.statustype_select) {
          if (this.statustypeDefault[this.statustype_select] === res.fields.handled) {
            warn_data_show.push(res)
          }
        }
      })
      warn_data_show.forEach(res => {
        if (!factory_arr.includes(res.fields.address)) {
          factory_arr.push(res.fields.address)
        }
        if (!handle_arr.includes(res.fields.handled)) {
          if (res.fields.handled === '1') {
            handle_arr.push('已处理')
          }
          if (res.fields.handled === '0') {
            handle_arr.push('待处理')
          }
        }
        if (!level_arr.includes(res.fields.level)) {
          level_arr.push(res.fields.level)
        }
        if (!warnning_arr.includes(res.fields.warnningType)) {
          warnning_arr.push(res.fields.warnningType)
        }
      })
      factory_arr.forEach(res_factory => {
        Object.keys(this.factorychild_options).forEach(res_factorychild => {
          if (this.factorychild_options[res_factorychild].includes(res_factory) && !factorychild_arr.includes(res_factorychild)) {
            factorychild_arr.push(res_factorychild)
          }
        })
      })

      this.factorychildtype_options = this.selectFormat(factorychild_arr)
      this.warnningtype_options = this.selectFormat(warnning_arr)
      this.leveltype_options = this.selectFormat(level_arr)
      this.factorytype_options = this.selectFormat(factory_arr)
      this.statustype_options = this.selectFormat(handle_arr)

      this.warn_data_show = warn_data_show
      this.warn_data_list = warn_data_show.slice(0, 10)
      this.currentPage = 1
      this.paginationNum = Object.keys(warn_data_show).length
      this.sendInformations(this.warn_data_list[0])
    },
    getAlarmData() {
      let param = this.$route.query // url参数
      let user = localStorage.getItem('Authorization')
      param['info'] = user
      queryAlarmAll(param).then(res => {
        let factoryTypeDefault = {}
        res.data.factory.forEach(res => {
          factoryTypeDefault[res.pk] = res.fields.factoryName
        })
        let warnningType = []
        let factoryType = []
        let levelType = []
        let statusType = ['已处理', '待处理']
        res.data.info.forEach(res => {
          res['show'] = false
          res['factory'] = factoryTypeDefault[res.fields.factory]
          let date = new Date(res.fields.datetime);
          res.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
          res.fields.createTime = null
          if (res.fields.handledTime !== null) {
            let date_Handled = new Date(res.fields.handledTime);
            res.fields.handledTime = this.$moment(date_Handled).format('YYYY-MM-DD HH:mm:ss')
          }

          warnningType.push(res.fields.warnningType)
          levelType.push(res.fields.level)
          if (factoryType.indexOf(res.fields.address) === -1) {
            factoryType.push(res.fields.address)
          }

        })
        this.warn_data = res.data.info
        this.warn_data_cache = res.data.info

        this.warnningtype_options = this.selectFormat(warnningType)
        this.leveltype_options = this.selectFormat(levelType)
        this.factorytype_options = this.selectFormat(factoryType)
        this.statustype_options = this.selectFormat(statusType)

        this.warnningtype_optionsDefault = this.selectFormat(warnningType)
        this.leveltype_optionsDefault = this.selectFormat(levelType)
        this.factorytype_optionsDefault = this.selectFormat(factoryType)
        this.statustype_optionsDefault = this.selectFormat(statusType)


        let l_start = (this.currentPage - 1) * 10
        let l_end = this.currentPage * 10
        this.warn_data_list = res.data.info.slice(l_start, l_end)

        this.paginationNum = Object.keys(res.data.info).length
        this.operatorRes = res.data.operator
        if (res.data.info.length <= 0) {
          this.sendInformations({'fields': ''})
          return
        }
        this.sendInformations(this.warn_data_list[0])
      })
      queryProjects().then(res => {
        let control_room_arr = []
        let control_room_dict_arr = []
        res.data.data.forEach(res => {
          if (control_room_arr.indexOf(res.control_room) === -1) {
            let control_room_dict = {}
            control_room_dict.value = res.control_room
            control_room_dict.label = res.control_room
            control_room_arr.push(res.control_room)
            control_room_dict_arr.push(control_room_dict)
          }
        })
        let control_room_address_dict = {}
        control_room_arr.forEach(res_control_room => {
          let address_arr = []
          res.data.data.forEach(res_address => {
            if (res_control_room === res_address.control_room) {
              address_arr.push(res_address.address)
            }
          })
          control_room_address_dict[res_control_room] = address_arr
        })
        this.factorychild_options = control_room_address_dict
        this.factorychildtype_options = control_room_dict_arr
        this.factorychildtype_optionsDefault = control_room_dict_arr
      })
    },
  },
  mounted() {
    this.getAlarmData()
  },
  watch: {
    $route() {
      //监听router变化 选中aside-item
      this.getAlarmData()
    },
    value_dateTime: {
      handler() {
        console.log(this.value_dateTime)
        if (!this.value_dateTime) {
          let cache = this.warn_data_cache
          this.warn_data = cache

          this.warn_data_list = cache.slice(0, 10)
          this.currentPage = 1
          this.paginationNum = Object.keys(cache).length
          this.sendInformations(this.warn_data_list[0])
        } else {
          let w_data = []
          this.warn_data_cache.forEach(res => {
            let w_dataTime = Number.parseInt(new Date(res.fields.datetime).getTime())
            if (this.value_dateTime[0] <= w_dataTime && w_dataTime <= this.value_dateTime[1] + 3600 * 1000 * 24) {
              w_data.push(res)
            }
          })
          this.warn_data = w_data
          this.warn_data_list = w_data.slice(0, 10)
          this.currentPage = 1
          this.paginationNum = Object.keys(w_data).length
        }
      },
    }
  }
}
</script>

<style scoped>

.warn-list {
  margin-top: 15px;
  height: 70vh;
  overflow: auto;
}

.warn-pagination {
  margin-top: 15px;
  margin-bottom: 15px;
  float: right;
}

.active {
  color: #409EFF;
  background-color: #F5F7FA;
}

.list {
  height: 80vh;
}
</style>